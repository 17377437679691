import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import extractMeta from 'utils/extract-meta'
import SEO from 'components/seo';
import TextBody from '../text/components/body';

// -------------------------------------------------
import DATA from './data';
// -------------------------------------------------

export default class ProductSerial extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      productName: '',
      productDetails: ''
    };
  }

  componentDidMount() {
    const page = DATA;

    if (window) {

      const serial = window.location.pathname.substr(3);
      const product = page['productDetails'][serial.substr(0, 4)];

      if (product) {
        this.setState({
          productName: product.name,
          productDetails: `<div>
            <p>${product.variant}</p>
            <p>&nbsp;</p>
            <p>Serial Number ${serial}</p>
            <p><a href="${product.link}">Download User Manual</a></p>
          </div>`
        });
      } else {
        this.setState({
          productName: 'Invalid Product'
        });
      }
    }
  }

  render() {
    const page = DATA;

    return (
      <Fragment>
        <SEO {...extractMeta(page)} />
        <TextBody
          title={this.state.productName}
          date=''
          bodyHtml={this.state.productDetails}
        />
      </Fragment>
    );
  }
}
