export default {
  pageTitle: 'Product Details',
  metaTitle: 'Product Details',
  metaDescription: 'Product Details',

  productDetails: {
    "PRMK": {
      "name": "Mobile Reader Pro",
      "variant": "Black, Mullion",
      "link": "https://support.proxy.com/hc/en-us/articles/360008046034-How-to-Setup-Mobile-Reader-Pro"
    },
    "PRMW": {
      "name": "Mobile Reader Pro",
      "variant": "White, Mullion",
      "link": "https://support.proxy.com/hc/en-us/articles/360008046034-How-to-Setup-Mobile-Reader-Pro"
    },
    "PRWK": {
      "name": "Mobile Reader Pro",
      "variant": "Black, Wall Mount",
      "link": "https://support.proxy.com/hc/en-us/articles/360008046034-How-to-Setup-Mobile-Reader-Pro"
    },
    "PRWW": {
      "name": "Mobile Reader Pro",
      "variant": "White, Wall Mount",
      "link": "https://support.proxy.com/hc/en-us/articles/360008046034-How-to-Setup-Mobile-Reader-Pro"
    },
    "PEMK": {
      "name": "Mobile Reader Edge",
      "variant": "Black",
      "link": "https://support.proxy.com/hc/en-us/articles/360020051934-How-to-Setup-Mobile-Reader-Edge"
    },
    "PERK": {
      "name": "Mobile Reader Edge",
      "variant": "Black",
      "name": "Mobile Reader Edge (Black)",
      "link": "https://support.proxy.com/hc/en-us/articles/360020051934-How-to-Setup-Mobile-Reader-Edge"
    },
    "PEMW": {
      "name": "Mobile Reader Edge",
      "variant": "White",
      "link": "https://support.proxy.com/hc/en-us/articles/360020051934-How-to-Setup-Mobile-Reader-Edge"
    },
    "PERW": {
      "name": "Mobile Reader Edge",
      "variant": "White",
      "link": "https://support.proxy.com/hc/en-us/articles/360020051934-How-to-Setup-Mobile-Reader-Edge"
    }
  }
};
